import { useStaticQuery, graphql } from "gatsby"

const useSolutionsForStartupsQuery = () => {
  const solutionsForStartupsQuery = useStaticQuery(graphql`
    query solutionsForStartupsQuery {
      allFile(
        filter: {
          extension: { eq: "png" }
          absolutePath: { regex: "/images/partners/" }
          name: { in: ["logo-4", "logo-7", "logo-8"] }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `)
  return solutionsForStartupsQuery
}
export default useSolutionsForStartupsQuery
