import codingOakfusionBlue from "../../data/images/products/coding-oakfusion-blue.png"

const solutionsForStartupsProjectData = [
  {
    id: 1,
    title: "Digital platform for contract management",
    image: codingOakfusionBlue,
    slug: "/case-studies/digital-platform-for-contract-management",
    description_1:
      "Application for creating and storing legal documents, where key features were the safety and efficiency. Allows to create, sign and store all your legal documents on a single digital platform to increase transparency and security in your organization.",
    description_2:
      "Oakfusion provided staff augmentation in order for a contract management company to scale their team and satisfy the demand for new features.",
    backgroundColor: "#21375d",
  },
]

export default solutionsForStartupsProjectData
