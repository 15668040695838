import puzzleIcon from "@iconify-icons/et/puzzle"
import notePencilThin from "@iconify-icons/ph/note-pencil-thin"
import shareNetworkThin from "@iconify-icons/ph/share-network-thin"
import numberListLine from "@iconify-icons/clarity/number-list-line"
import cursorHandClickLine from "@iconify-icons/clarity/cursor-hand-click-line"
import starO from "@iconify-icons/iwwa/star-o"

const solutionsForStartupsChainData = [
  {
    id: 1,
    title: "Planing",
    image: notePencilThin,
    text: "",
  },
  {
    id: 2,
    title: "Analysis",
    image: puzzleIcon,
    text: "",
  },
  {
    id: 3,
    title: "Design",
    image: shareNetworkThin,
    text: "",
  },
  {
    id: 4,
    title: "Implementation",
    image: numberListLine,
    text: "",
  },
  {
    id: 5,
    title: "Testing and integration",
    image: cursorHandClickLine,
    text: "",
  },
  {
    id: 6,
    title: "Maintenance",
    image: starO,
    text: "",
  },
]

export default solutionsForStartupsChainData
