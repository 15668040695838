import styled from "styled-components"
import IBackground from "./types"

export const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const Color = styled.div<IBackground>`
  background-color: ${props => props.bgColor};
  width: 100%;
  height: 100%;
  position: absolute;
`
