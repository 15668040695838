import { useStaticQuery, graphql } from "gatsby"

const useSolutionsForStartupProjectQuery = () => {
  const solutionsForStartupProjectQuery = useStaticQuery(graphql`
    query solutionsForStartupProjectQuery {
      allFile(
        filter: {
          extension: { eq: "png" }
          name: { in: ["coding-oakfusion-blue"] }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  return solutionsForStartupProjectQuery
}

export default useSolutionsForStartupProjectQuery
