import React from "react"
import { Container, Wrapper, ContactWrapper } from "./style"
import {
  H2Title,
  H3Title,
  Section,
  DoubleGrid,
  OrangeSpan,
  P,
  M,
} from "../../../globalStyle"
import Background from "../../Elements/Background"
import { useTheme } from "styled-components"
import Button from "../../UI/Button"

const SuccessfullStartup = () => {
  const theme = useTheme()
  return (
    <Section>
      <Container>
        <M>
          <H2Title center={true}>
            We know that <OrangeSpan>successful startup is...</OrangeSpan>
          </H2Title>
        </M>
        <DoubleGrid>
          <Background bgColor={theme.colors.darkBlue}>
            <Wrapper>
              <H3Title>50% your product</H3Title>
              <P color={theme.colors.white}>
                We take care of this part and provide you with the software you
                need. Curious how we do it? Let’s have a calland create workflow
                for your product together.
              </P>
            </Wrapper>
          </Background>
          <Background bgColor={theme.colors.orange}>
            <Wrapper>
              <H3Title>50% marketing strategy</H3Title>
              <P color={theme.colors.white}>
                Efficient traction – plan your strategy well and get clients
                even before your project is finished. Check out our blog to see
                how it can be achieved.
              </P>
            </Wrapper>
          </Background>
        </DoubleGrid>
        <ContactWrapper>
          <M>
            <H3Title isDarkBlue={true}>
              We will help you in planning your product and{" "}
              <OrangeSpan>
                our project team will estimate it for free!
              </OrangeSpan>
            </H3Title>
          </M>
          <div>
            <Button slug="/contact">Order Free Consultation</Button>
          </div>
        </ContactWrapper>
      </Container>
    </Section>
  )
}

export default SuccessfullStartup
