import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import QuickContact from "../components/Sections/QuickContact"
import Button from "../components/UI/Button"
import { OrangeSpan, GrayBorder, Section, DoubleGrid } from "../globalStyle"
import Companies from "../components/Sections/Companies"
import useSolutionsForStartupsQuery from "../helpers/queries/solutionsForStartupsQuery"
import TrustedUs from "../components/Sections/TrustedUs"
import PageTitle from "../components/Elements/PageTitle"
import solutionsForStartupsChainData from "./../data/components/solutionsForStartupsChain"
import ChainDevelopment from "../components/Sections/ChainDevelopment"
import ClutchTitle from "../components/Sections/ClutchTitle"
import { Container } from "../components/Sections/CaseStudies/style"
import useSolutionsForStartupProjectQuery from "../helpers/queries/solutionsForStartupsProjectQuery"
import solutionsForStartupsProjectData from "../data/components/solutionsForStartupsProject"
import Project from "../components/UI/Project"
import SuccessStartup from "../components/Sections/SuccessfullStartup"

const SolutionsForStartups = () => {
  const solutionsForStartupsData = useSolutionsForStartupsQuery()
  const solutionsForStartupsImageData = useSolutionsForStartupProjectQuery()
  return (
    <Layout>
      <Head
        description="Develop your product with Oakfusion 🚀 We create web and mobile applications for startups. We will estimate it for free ✅ Discuss your project with us"
        pageTitle="Solutions For Startups | We build software products from scratch."
        keywords="software, oakfusion"
      />
      <div style={{ marginBottom: "150px" }}></div>
      <Section>
        <PageTitle title="Solutions for Startups" />
      </Section>
      <GrayBorder />
      <ClutchTitle
        title="We build"
        coloredTitle="web and mobile applications"
        text="Launch your startup today! Develop your product from scratch with Oakfusion – web and mobile application developer trusted by startups from all over the world."
        Button={() => <Button slug="/contact">Order free consultation</Button>}
      />

      <SuccessStartup />

      <Section>
        <Container>
          <DoubleGrid>
            {solutionsForStartupsProjectData.map(p => (
              <Project
                key={p.id}
                isInService={true}
                data={p}
                image={
                  solutionsForStartupsImageData.allFile.edges[p.id - 1].node
                    .childImageSharp.gatsbyImageData
                }
              />
            ))}
          </DoubleGrid>
        </Container>
      </Section>
      <ChainDevelopment chainElements={solutionsForStartupsChainData} />
      <TrustedUs
        text="Oakfusion’s teamwork contributed to the client’s increase in sales since their collaboration. They were effective in adjusting to the client’s workflow and seamlessly acted as a member of the team."
        name="CPO & Founder"
        who="Contract Management Company"
      />
      <Companies companies={solutionsForStartupsData} />
      <QuickContact
        title={
          <>
            Got other <OrangeSpan>ideas?</OrangeSpan>
          </>
        }
        text={"Make it happen"}
        button={<Button slug="/contact">Contact</Button>}
      />
    </Layout>
  )
}

export default SolutionsForStartups
