import React, { PropsWithChildren } from "react"
import IBackground from "./types"
import { Color, BackgroundWrapper } from "./style"

const Background = (props: PropsWithChildren<IBackground>) => {
  const { bgColor, children } = props
  return (
    <BackgroundWrapper>
      <Color bgColor={bgColor} />
      {children}
    </BackgroundWrapper>
  )
}

export default Background
